@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

/* On changing the fonts and icon size we have to change the following props in components "& .MuiButton-startIcon": { marginTop: "5px" } */

/* html,
body,
#root {
  height: calc(100% - 35px);
} */

body {
  margin: 0;
  background-color: #dddddd
    /* background-color:#f6f6f6; */
}

/* .even .MuiCard-root {
  background-color: #f9f9f9;
} */
.cell-txt-bold {
  font-weight: 600;
}

.cell-blk-wt {
  background-color: black;
  color: white;
}

.cell-fg-wt {
  background-color: forestgreen;
  color: white;
}

.cell-blu-wt {
  background-color: blue;
  color: white;
}

.cell-rd-wt {
  background-color: red;
  color: white;
}

.cell-mn-wt {
  background-color: Maroon;
  color: white;
}

.cell-hp-wt {
  background-color: hotpink;
  color: white;
}

.cell-dg-wt {
  background-color: DimGray;
  color: white;
}

.cell-pr-wt {
  background-color: purple;
  color: white;
}

.cell-status--red {
  color: white !important;
  background-color: #ff0000 !important;
}

/* New user modal check boxes margin */
.ml-24 {
  margin-left: 24px;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #BFBFBF;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

#resz {
  width: 100%;
  min-height: 150px;
  height: 150px;
  position: relative;
  margin-bottom: 10px;
}

div .resizer {
  width: 50px;
  height: 10px;
  border-radius: 25px;
  border: 1px solid black;
  background: white;
  position: absolute;
  right: 50%;
  bottom: -15px;
  cursor: n-resize;
  z-index: 10;
}